
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import { LineChart } from 'echarts/charts';
import { Card, Modal } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FormatCurrency from 'components/pages/sistema/formatCurrency';
import DetailIcon from "../../../../assets/img/icons/request-system/detail.svg";
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import generateRandomHexColor from 'components/pages/sistema/randomColorGenerator';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import LoadingAnimation from 'components/pages/sistema/loadingAnimation';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data) => ({
  tooltip: {
    trigger: 'item',
    backgroundColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    formatter: '<strong>{b}</strong><br/> {c} ({d}%)'
  },
  legend: { show: false },
  series: [
    {
      name: 'Produtos Mais Vendidos',
      type: 'pie',
      radius: ['65%', '90%'],
      selectedMode: 'single',
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      labelLine: { show: false },
      data: data
    },
  ]
});

const VendasProduto = ({ data, showDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  data = data.map((item) => {
    return {
      value: item.total,
      lojas: item.lojas,
      name: item.produtoNome,
      itemStyle: {
        color: generateRandomHexColor()
      }
    }
  });

  const VendasProdutoList = ({ data }) => {
    return (
      <Row className="g-3 font-sans-serif">
        <Col sm={12}>
          <div className="d-flex align-items-center mb-4">
            <h6 className="mb-0 fw-bold">Lojas</h6>
          </div>
          <div className="rounded-3 p-3 vendas-justify">
            
            <ul className="fill-available-width list-unstyled mb-0">
              {data.map((item, index) => (
                <li
                  key={item.name}
                  className={`li-dashboard-with-button d-flex fs--2 fw-medium pt-1 align-center ${
                    index !== data.length - 1 && 'mb-3'
                  }`}
                >
                  <div className='align-center flex-row'>
                    <FontAwesomeIcon
                      icon="circle"
                      className='me-2'
                      color={item.itemStyle?.color}
                    />
                    <p className="lh-sm mb-0 text-700 vendas-text">
                      {item.name}
                    </p>
                    <p className="lh-sm mb-0 text-700 vendas-text">
                      <span className="text-900 ps-2"><FormatCurrency number={item.value} /></span>
                    </p>
                  </div>
                  { showDetails ? 
                    <Button 
                      title='Detalhes'
                      onClick={() => { setShowModal(true); setCurrentItem(item); }}
                      className='detail-vendas-produto button-in-dashboard'
                    >
                      <img src={DetailIcon} className='button-icon'/>
                    </Button> : <></>
                  }
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };
  
  const VendasProdutoChart = ({ data }) => {
    const chartRef = useRef(null);
  
    return (
      <div className="position-relative py-2">
        <ReactEChartsCore
          ref={chartRef}
          echarts={echarts}
          option={getOptions(data)}
          style={{ minHeight: '18.75rem' }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <div className="rounded-circle d-flex flex-center marketing-exp-circle">
            <h4 className="mb-0 text-900">
              <FormatCurrency number={(data.reduce((soma, item) => soma + item.value, 0))} />
            </h4>
          </div>
        </div>
      </div>
    );
  };

  const modalColumns = [
    {
      accessor: 'nome',
      Header: 'Loja'
    },
    {
      accessor: 'total',
      Header: 'Receita',
      Cell: rowData => {
        const { total } = rowData.row.original;

        return <FormatCurrency number={total} />
      }
    }
  ];

  return (
    <Card className="h-100 dashboard-card-shadow">
      <Card.Body className="d-flex flex-column justify-content-between">
        <h4 className="mt-1">Produtos Mais Vendidos</h4>
        { data.length <= 0 ?
          <LoadingAnimation /> :
          <>
            <VendasProdutoChart data={data} />
            <VendasProdutoList data={data} />
          </>
        }  
      </Card.Body>

      <Card.Footer className="bg-light py-2 d-flex">
        <div className="ms-auto">
        </div>
      </Card.Footer>
      <Modal
        centered
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Detalhamento de vendas do produto por loja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdvanceTableWrapper
            sortable
            pagination
            perPage={10}
            columns={modalColumns}
            data={currentItem?.lojas ?? []}
          >
            <AdvanceTable
              table
              rowClassName="align-middle white-space-nowrap"
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableWrapper>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default VendasProduto;