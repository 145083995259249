import Lottie from 'lottie-react';
import React, { useEffect, useRef } from "react";
import loading from '../../../assets/img/animated-icons/loading.json';

export default function LoadingAnimation({height}) {
  const lottieRef = useRef;

  useEffect(() => {
    lottieRef.current.setSpeed(1.75);
  }, [lottieRef])

  return (
    <>
      <div style={{
          height,
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Lottie
          loop={true}
          lottieRef={lottieRef}
          animationData={loading}
          style={{
            width: '175px',
            height: '175px'
          }} 
        />
      </div>
    </>
  );
}